import React from 'react';
import { Link } from 'gatsby';
import useSiteData, { isTransactional } from '../../hooks/siteData';
import styles from './style.module.css';

export default function Breadcrumbs(props) {
    const category = props.category || getLowestCategory(props.categories);
    const ignoreLast = (props.category || category?.url_key.endsWith('-flow-cells')) ? true : false;   // if we are in a category we want to ignore the current category
    const trail = buildTrail(category, ignoreLast);
    const last = trail.length - 1;

    const Content = () => {
        return (trail.length > 1)
            ? trail.map((crumb, i) => <Breadcrumb crumb={crumb} isLast={i===last} key={i} />)
            : <span>&nbsp;</span>
    }

    return(
        <div className={styles.breadcrumbs}>
            <Content />
        </div>
    );
}

function Breadcrumb({crumb, isLast}) {
    if (isLast) {
        return <BreadcrumbLink crumb={crumb} />;
    }

    return (
        <span>
            <BreadcrumbLink crumb={crumb} /> <span className={styles.slashIcon}>&#47;</span>
            {/* <FontAwesomeIcon className={styles.faSlash} icon={faSlash} size="sm" /> */}
        </span>
    );
}

function BreadcrumbLink({crumb}) {
    if (crumb.url.startsWith('//') || crumb.url.startsWith('http')) {
        return <a href={crumb.url}>{crumb.label}</a>;
    }
    return <Link to={crumb.url}>{crumb.label}</Link>;
}

function buildTrail(category, ignoreLast)
{
    const { home, productsHome, servicesHome } = useSiteData();
    const trail = [];
    const isTxnl = isTransactional();

    if (isTxnl) {
        trail.push({label: 'Home', url: home});
    }

    if (category)
    {
        if (category.breadcrumbs?.length > 0)
        {
            const sortedBreadcrumbs = [...category.breadcrumbs].sort(function (a, b) {
                return a.category_level - b.category_level;
            });

            sortedBreadcrumbs.forEach(crumb => {
                switch (crumb.category_url_key) {
                    case 'products':
                        trail.push({label: (isTxnl ? 'Products' : 'Home'), url: productsHome});
                        break;
                    case 'training':
                        trail.push({label: (isTxnl ? 'Services' : 'Home'), url: servicesHome});
                        break;
                    default:
                        trail.push({label: crumb.category_name, url: `/${crumb.category_url_key}.html`});
                }
            });

            if (!ignoreLast) trail.push({label: category.name, url: `/${category.url_key}.html`});
        }
        else
        {
            trail.push({label: category.name, url: ''});
        }
    }

    return trail;
}

function getLowestCategory(categories)
{
    if (categories && categories.length)
    {
        const sortedCategories = [...categories].sort(function (a, b) {
            return b.level - a.level;
        });

        return sortedCategories[0];
    }

    return null;
}
