import React from 'react';
import styles from './style.module.css';
import UiMarkdown from "../../Ui/UiMarkdown";
import { Price } from "../../../magento/peregrine/lib";
import cx from 'classnames';
import { Link } from "gatsby";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/pro-light-svg-icons";
import {isTransactional, shouldShowPrice} from "../../../hooks/siteData";
import {useCustomerContext} from "../../../contexts/CustomerContext";
import ContentfulImage from "../../Contentful/ContentfulImage";


export default function CatalogContent({category, filters, textFilter}) {
    const products = category.products.items.length ? getFilteredProducts(category.products.items, filters, textFilter) : [];
    const categoryChildren = category.children.length ? getFilteredCategories(category.children, textFilter) : [];
    const itemCount = products.length + categoryChildren.reduce((acc,curr) => curr.products.items?.length ? acc+1 : acc, 0);

    return (
        <div className={styles.content} data-cy="catalogContent">
            <div className={styles.itemCount}>
                {itemCount} {(itemCount === 1) ? 'item' : 'items'}
            </div>
            {products.map(product => <StandardItem key={'p' + product.id} product={product} />)}
            {categoryChildren.map(child => <RenderSubCategory key={'c' + child.id} subCategory={child} />)}
        </div>
    );
}

const RenderSubCategory = ({subCategory}) => {
    if (!subCategory.products.items?.length) return null;

    switch(subCategory.ont_category_type) {
        case 'CONFIGURATOR':
            return <ConfiguratorItem subCategory={subCategory} />;
        case 'FLOWCELLS':
            return <FlowcellsItem subCategory={subCategory} />;
        case 'FAMILY':
            return <FamilyItem subCategory={subCategory} />;
    }

    return subCategory.products.items.map(product => <StandardItem key={'p' + product.id} product={product} />);
}

const ConfiguratorItem = ({subCategory}) => {
    return (
        <>
        <div id={'configurator-' + subCategory.url_key} className={cx(styles.configurator, styles.item)}>
            <h2 className={styles.title}>{subCategory.name}</h2>
            <div className={styles.details}>
                <div className={styles.innercontent}>
                <div className={styles.image}><img src={subCategory.ont_image_url_1} /></div>
                <div className={styles.description}>
                    <UiMarkdown source={subCategory.description} />
                    <div className={styles.priceContainer}>
                    <ProductPrice prefix="Packages from: " product={getCheapestProduct(subCategory)} />
                    <CtaButton target={`/${subCategory.url_key}.html`} />
               </div>
                </div>
                </div>

            </div>
        </div>
        </>
    );
}

const FamilyItem = ({subCategory}) => {
    return (
        <div id={'family-' + subCategory.url_key} className={cx(styles.configurator, styles.item)}>
            <h2 className={styles.title}>{subCategory.name}</h2>
            <div className={styles.details}>
            <div className={styles.innercontent}>
                <div className={styles.image}><Thumbnail src={subCategory.ont_image_url_1} /></div>
                <div className={styles.description}>
                    <UiMarkdown source={subCategory.description}  />
                    <ProductPrice prefix="Packages from: " product={getCheapestProduct(subCategory)} />
                    <CtaButton target={getProductUrlPath(subCategory.products.items[0])} />
                </div>
                </div>
            </div>
        </div>
    );
}

const FlowcellsItem = ({subCategory}) => {
    return (
        <div className={cx(styles.flowcells, styles.item)}>
            <h2 className={styles.title}>{subCategory.name}</h2>
            <div className={styles.flowDetails}>
                <div className={styles.image}><Thumbnail src={subCategory.ont_image_url_1} /></div>
                <div className={styles.description}>
                    <UiMarkdown source={subCategory.description} />
                    <div className={styles.priceFlowContainer}>
                    <ProductPrice prefix="Single flow cells from: " product={getCheapestProduct(subCategory)} />
                    <CtaButton target={getProductUrlPath(subCategory.products.items[0])} />
                </div>
                </div>
            </div>
        </div>
    );
}

const StandardItem = ({product}) => {
    return (
        <div className={cx(styles.standard, styles.item)}>
            <h2 className={styles.title}>{product.name} <span className={styles.sku}>{product.sku}</span></h2>
            <div className={styles.details}>
                <div className={styles.image}><Thumbnail src={product.ont_image_url_1} /></div>
                <div className={styles.description}>
                    <UiMarkdown source={product.description.html} skipHtml />
                    <ul className={styles.attributePoints}>
                        {product.ont_prep_display && <li>Preparation time: {product.ont_prep_display}</li> }
                        {product.ont_throughput && <li>Throughput: {product.ont_throughput}</li> }
                        {product.ont_read_len_display && <li>Read length: {product.ont_read_len_display}</li> }
                        {product.ont_concentration && <li>Input amount: {product.ont_concentration}</li> }
                    </ul>
                    <ProductPrice product={product} />
                    <CtaButton target={getProductUrlPath(product)}/>

                </div>

            </div>
        </div>
    );
}

const ProductPrice = ({product, prefix}) => {
    if (!product || !shouldShowPrice(product.sku)) {
        return null;
    }

    const { currency, value } = product.price_range.minimum_price.final_price;
    return (
        <div className={styles.price}>
            {prefix && <span>{prefix}</span>}
            <strong><Price currencyCode={currency} value={value} /></strong>
        </div>
    );
}

const CtaButton = ({target}) => {
    const buttonText = isTransactional() ? 'Buy' : 'Learn';

    return (
        <div className={styles.cta}>
            <Link className="button button--primary button--keyline" to={target}>
                <span>{buttonText} <FontAwesomeIcon icon={faChevronRight} /></span>
            </Link>
        </div>
    );
}

const Thumbnail = ({src}) => {
    return <ContentfulImage src={src} width={600} />
}

const getProductUrlPath = product => {
    const {customer} = useCustomerContext();
    return customer.has_extra_permission ? `/productDetail/${product.url_key}` : `/${product.url_key}.html`
}

const getCheapestProduct = category => {
    const sortedProducts = [...category.products.items].sort(function (a, b) {
        if(!shouldShowPrice(a.sku)) return 1;
        if(!shouldShowPrice(b.sku)) return 1;
        if (a.price_range.minimum_price.final_price.value === b.price_range.minimum_price.final_price.value) {
            return 0;
        }

        return a.price_range.minimum_price.final_price.value - b.price_range.minimum_price.final_price.value;
    });

    return sortedProducts[0];
}

const getFilteredProducts = (products, filters, textFilter) => {
    return getTextFilteredProducts(products, textFilter).filter(product => {
        for (let key in filters) {
            if (parseInt(filters[key]) === 1 || parseInt(filters[key]) === 0) {
                // filter is boolean
                if (!(product[key] === undefined || product[key] === null || product[key] == filters[key])) {
                    // Attribute value is set in magento but does not match
                    return false;
                }
            }
            else {
                // Not a boolean filter
                if (product[key] === undefined || product[key] != filters[key]) {
                    // Attribute not set in magento or does not match
                    return false;
                }
            }
        }
        return true;
    });
}

const getTextFilteredProducts = (products, textFilter) => {
    if (! textFilter) {
        return products;
    }

    return products.filter(product => {
        return doesFieldMatch(textFilter, [
            product.name,
            product.sku,
            product.description.html
        ]);
    })
}

const getFilteredCategories = (categories, textFilter) => {
    const allowedCategories = categories.filter(category => {
        return ['CONFIGURATOR','FLOWCELLS','FAMILY'].includes(category.ont_category_type);
    });

    if (! textFilter) {
        return allowedCategories;
    }

    return allowedCategories.filter(category => {
        return doesFieldMatch(textFilter, [category.name, category.description]);
    })
}

const doesFieldMatch = (textFilter, fields) => {
    for (const field of fields) {
        if (field.toLowerCase().includes(textFilter.toLowerCase())) {
            return true;
        }
    }
    return false;
}
