import React from 'react';
import styles from "./style.module.css";
import cx from 'classnames';
import Breadcrumbs from '../Breadcrumbs';
import { NanoGrid, NanoGridItem } from '@nanoporetech-digital/components-react';

export default function CatalogBanner({category}) {

    if (!category) {
        return null; // TODO maybe a nice banner for the landing page?
    }

    const categoryClass = category.name.split(' ')[0].toLowerCase();

    return (
        <>
        <div className={styles.mainBanner}>
            <NanoGrid l-cols="2" s-cols="1" xl-cols="10" className={cx(styles[categoryClass])}>
                <div className={styles.containerImage}>
                    <img className={styles.bannerImage} alt={category.name} src={category.ont_image_url_1} />
                </div>

                <div className="overlay" />

                <NanoGridItem className={cx(styles.banner)} grid-states="xl-col-span-8 xl-col-start-2 xl-row-start-1 l-col-span-2 l-row-start-2">
                    <div className={styles.title}>
                        <Breadcrumbs category={category} />
                        <h2 className="heading--two">{category.name}</h2>
                    </div>
                </NanoGridItem>
            </NanoGrid>
        </div>
        <style jsx>{`
            @media only screen and (max-width: 768px) {
                .overlay {
                    position: absolute;
                    bottom: 0;
                    background: rgba(0, 0, 0, 0.7);
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    opacity:.5;
                    width:100%;height:100%;
                }
            }
        `}</style>
        </>
    );
}
