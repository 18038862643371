import React from 'react';
import UiRadio from "../../Ui/UiRadio";
import styles from './style.module.css';
import { NanoCheckbox, NanoCheckboxGroup } from '@nanoporetech-digital/components-react';

export default function CatalogFilters(props) {
    if (props.category.url_key !== 'sequencing-kits') return null;

    return(
        <div className={'filters ' + styles.filters}>
            <div id="clear-filters" className={styles.clear}>
                <a href="#" onClick={() => props.setFilters({})}>Reset filters</a>
            </div>
            <div className={styles.panel}>
            {/* <NanoCheckboxGroup> */}
            <FilterList {...props} />
            {/* </NanoCheckboxGroup> */}

            </div>
        </div>
    );
}

const FilterList = (props) => {
    return props.attributes.map(attribute => <FilterAttribute {...props} key={attribute.attribute_code} attribute={attribute} />);
}

const FilterAttribute = (props) => {
    return(
        <div className={styles.item}>
            <div className={'attributeLabel ' + styles.panelHeading}>{attributeLabels[props.attribute.attribute_code]}
            <FilterOptions {...props} />
            </div>
        </div>
    );
}

const FilterOptions = ({attribute, filters, setFilters}) => {
    const code = attribute.attribute_code;
    return attribute.attribute_options.map(option => {
        return (
            <div class={styles.filterCheckbox} key={code + '_' + option.value}>
                {/* <UiRadio
                key={code + '_' + option.value}
                className="filterRadio"
                name={code}
                value={option.value}
                onChange={e => setFilters({...filters, [e.currentTarget.name]: e.currentTarget.value})}
                checked={filters.hasOwnProperty(code) && filters[code] === option.value}>
                    {option.label}
            </UiRadio> */}
            <NanoCheckbox
                type="radio"
                className="filterCheckbox"
                value={option.value}
                name={code}
                key={code + '_' + option.value}
                label={option.label}
                checked={filters.hasOwnProperty(code) && filters[code] === option.value}
                onNanoChange={e => {
                    if (e.detail.checked) {
                        setFilters({...filters, [e.currentTarget.name]: e.currentTarget.value })
                    }
                }}
            />
            </div>
        )
    });
}

//TODO GraphQL endpoint needs enhancing, to get this from Magento
const attributeLabels = {
    ont_sample_type: "Sample type",
    ont_multiplexing: "Multiplexing",
    ont_approach: "PCR-Free"
};
