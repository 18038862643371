import React, { Fragment } from 'react';
import styles from "./style.module.css";
import Link from 'gatsby-link';
import cx from 'classnames';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import {useCustomerContext} from "../../../contexts/CustomerContext";
import { NanoIcon } from '@nanoporetech-digital/components-react';

const sections = {
    'Flow cells': 'Consumables',
    'Devices': ' ',
    'Rapid start day': 'Training',
    'Certification': 'Services',
};

export default function CatalogNav(props) {
    return (
        <div className={'navigation ' + styles.nav}>
            <Link to="/" activeClassName={styles.active}><div className={styles.navItem}>Home</div></Link>
            <CatalogNavList {...props} />
        </div>
    );
}

const CatalogNavItem = ({slug, name, id, categoryId, hasParent, isDirect}) => {
    return(
        <Link to={getCategoryUrlPath(slug, categoryId)} activeClassName={styles.active}>
            <div id={id} className={cx(styles.navItem, hasParent ? styles.hasParent : null)}>
                <div>{name}</div>
                {isDirect && <NanoIcon  name="light/chevron-right"></NanoIcon>}
                {/* {isDirect && <div><FontAwesomeIcon icon={faChevronRight} size="sm" /></div>} */}
            </div>
        </Link>
    );
}

const CatalogNavList = props => {
    const children = getChildren(props.categories);
    const flattened = flatten(children, props.activeCategory);    // bring in children of active anchor categories

    return flattened.map(category => {
        return(
            <Fragment key={category.id}>
                <SectionHeading category={category} />
                <CatalogNavItem
                    slug={category.url_key}
                    id={'navigation-' + category.url_key}
                    categoryId={category.id}
                    name={category.name}
                    hasParent={category.hasParent}
                    isDirect={category.level ? false : true} />
            </Fragment>
        );
    });
}

const isActive = (category, activeCategory) => {
    if (activeCategory === 0) return false;
    if (category.id === activeCategory.id) return true;

    // are any of this category's children active?
    if (category.is_anchor && category.children) {
        return category.children.reduce((found, subcategory) => {
            return (found || subcategory.id === activeCategory.id);
        }, false);
    }

    return false;
}

const sort = categories => {
    return categories.slice().sort(function (a, b) {return a.position - b.position;});
}

// for children of active anchor categories, surfaces the children to the same level as parent
const flatten = (categories, activeCategory) => {
    const flattened = [];

    categories.forEach(category => {
        flattened.push(category);
        if (isActive(category, activeCategory) && category.is_anchor && category.children) {
            const sortedChildren = sort(category.children);
            sortedChildren.forEach(child => {
                flattened.push({...child, hasParent: true});
            });
        }
    });

    return flattened;
}

const getChildren = categories => {
    const sortedParents = sort(categories);
    const allChildren = [];
    sortedParents.forEach(parent => {
        const sortedChildren = sort(parent.children);
        allChildren.push(...sortedChildren);
        if (parent.products?.items?.length > 0) {
            allChildren.push(...parent.products.items);
        }
    })
    return allChildren;
}

const SectionHeading = ({category}) => {
    if (sections[category.name] !== undefined) {
        return <div className={styles.sectionName} data-cy={'heading-' + sections[category.name]} >
            {sections[category.name]}
        </div>
    }

    return null;
}

const getCategoryUrlPath = (slug, id) => {
    const {customer} = useCustomerContext();
    return customer.has_extra_permission ? `/catalog/${id}` : `/${slug}.html`;
}
