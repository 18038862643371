import React from 'react';
import Markdown from 'react-markdown';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const BlockquoteRenderer = props => {
    return(
        <>
            {
                props.children.map((child, i) => (
                    <div key={i}>
                        <strong>
                            <FontAwesomeIcon icon={faInfoCircle} />&nbsp;
                            <span>{child.props.children[0].props.children}</span>
                        </strong>
                    </div>
                ))
            }
            <style jsx>{`
                div {
                    color: #0084a9;
                }
            `}</style>
        </>
    );
};

export default function UiMarkdown(props) {
    return (
        <Markdown {...props} renderers={{blockquote: BlockquoteRenderer}} />
    );
}
