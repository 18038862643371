import React, { useState,useEffect, useRef } from 'react';
import CatalogBanner from './catalogBanner';
import CatalogNav from './CatalogNav';
import CatalogFilters from './CatalogFilters';
import CatalogSearch from './CatalogSearch';
import CatalogContent from './CatalogContent'
import TopActions from "../TopActions";
import { NanoGrid, NanoGridItem, NanoIcon } from '@nanoporetech-digital/components-react';
import Landing from './landing';
import styles from "./style.module.css";


export default function Catalog({category, categories, filterAttributes}) {
    const [filters, setFilters] = useState(getPreFilter(filterAttributes));
    const [textFilter, setTextFilter] = useState('');
    const [isSticky, setSticky] = useState(false);
    const ref = useRef(null);
    const handleScroll = () => {
        const offset=window.scrollY;

        if (ref.current) {
            setSticky(ref.current.getBoundingClientRect().top <=0 &&  offset >0 );
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', () => handleScroll);
        };
    }, [handleScroll]);

    function ContentArea() {
        if (category) {
            return(
                <>
                    <form className={styles.catalogControls}>
                        <CatalogSearch textFilter={textFilter} setTextFilter={setTextFilter}/>
                        <CatalogFilters category={category}
                            attributes={filterAttributes}
                            filters={filters}
                            setFilters={setFilters}
                        />
                    </form>
                    <CatalogContent category={category} filters={filters} textFilter={textFilter}/>
                </>
            );
        }

        return <Landing />;
    }

    return(
        <>
        <TopActions grid-states="l-col-start-2" isLight={true} main="main"  category={category}/>
        <div className={styles.catalog}>
            <div className={styles.header}>
                <CatalogBanner category={category} />
            </div>
            <NanoGrid l-cols="2" s-cols="1"  xl-cols="10" className={styles.content}>
                <NanoGridItem className={styles.aside} grid-states="xl-col-span-2 xl-col-start-1 xl-row-start-1 l-col-span-2 l-row-start-1">
                    <input id="menu-toggle" type="checkbox" className="checkbutton"/>
                    <label htmlFor="menu-toggle" className='openLabel' className={`openLabel${isSticky ? ' sticky' : ''}`} ref={ref}>
                        <NanoIcon name="light/ellipsis-v"  className="openIcon"></NanoIcon>
                    </label>
                    <div className="navigationMenu">
                        <label htmlFor="menu-toggle"  className="closeLabel">
                            <NanoIcon name="light/times"  className="closeIcon"></NanoIcon>
                        </label>
                        <CatalogNav categories={categories} activeCategory={category ?? 0} className="navigation"  id="nav"/> 
                    </div>
                </NanoGridItem>
                <NanoGridItem className={'article ' + styles.article} grid-states="xl-col-span-8 xl-col-start-3 xl-row-start-1 l-col-span-2 l-row-start-2">
                    <ContentArea />
                </NanoGridItem>
            </NanoGrid>
        </div>
        <style jsx>{`
            @media only screen and (max-width: 900px){
                .navigationMenu{
                    clip: rect(0 0 0 0);
                    position: absolute;
                    transform:translate(-100%);
                    height:100%;
                    background-color:white;
                    transition:transform .3s cubic-bezier(0, 0, 0.3, 1);

                }
                input[type='checkbox']:checked ~ div {
                    transform:translate(0);
                    z-index: 10;
                    clip: rect(auto auto auto auto);
                    top:0;
                    height: 100%;
                    position: fixed;
                    overflow-y:scroll;
                    overflow-x:hidden;
                    width:100vw;
                    transition:transform .3s cubic-bezier(0, 0, 0.3, 1);
                }

                input[type='checkbox']{
                    clip: rect(0 0 0 0);
                    position: absolute;
                }

                 .closeLabel{
                    clip: rect(auto auto auto auto)!important;
                    position:fixed!important;
                }

                .openLabel{
                    // position:fixed!important;
                    visibility: visible!important;
                }
                .article{
                    padding:0 1rem!important;
                 }


                .closeLabel{
                    width: 100%;
                    top: 0;
                    position: absolute!important;
                    z-index:11;
                    outline: none;
                    box-shadow: 0px 15px 10px -15px #111;
                    transition:transform .4s ease-in-out;
                }

             }


             .openIcon{
                    width: 40px;
                    height: 50px;
                    box-shadow: 0 1px 5px 0 rgb(0 0 0 / 25%);
                    background-color: #ccdee3;
                    bottom: 1rem;
                    position: relative;
                    left: 1rem;
                }

                .closeLabel{
                    background-color: white;
                    height: 4rem;
                    padding: 16px;
                    display: flex;
                    justify-content: flex-end;
                    clip: rect(0 0 0 0);
                    position: absolute;
                    border: solid 1px #dcdcdc;
                    box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.1);
                }

                .noscroll {
                    overflow: hidden;
                  }

                .openLabel{
                    background-color: #e5eef1;
                    height: 54px;
                    color: #fff;
                    padding: 16px;
                    display: block;
                    display: flex;
                    justify-content: flex-end;
                    // clip: rect(0 0 0 0);
                    position: absolute;
                    visibility: hidden;
                    // top: 0;
                    left: 0;
                    right: 0;
                    z-index: 1;
                }
                .checkbutton{
                    clip: rect(0 0 0 0);
                    position: absolute;
                }

                input[type='checkbox'] ~ .navigation {
                    background: green;
                    height: 50px;
                    color: #fff;
                    padding: 16px;
                    display: block;
                    }

                .sticky {
                    position: fixed;
                    top: 0;
                    left: 0;
                     right: 0;
                    z-index: 2;
                    }


                .article{
                    width: 110%;
                    left: -6%;
                    position: relative;
                 }

                 @media only screen and (max-width: 900px),
    all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
                    .navigationMenu{
                        right:0px!important;
                    }

                 }


        `}</style>
        </>
    );

}

function getPreFilter(attributes) {
    if (typeof window === "undefined") {
        return {};
    }
    const urlParams = new URLSearchParams(location.search);
    const filter = urlParams.get('filter');
    const preFilter = {};

    if (filter) {
        attributes.forEach(attribute => {
            attribute.attribute_options.forEach(option => {
                if (option.label.toLowerCase() === filter.toLowerCase()) preFilter[attribute.attribute_code] = option.value;
            });
        });
    }

    return preFilter;
}
