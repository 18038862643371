import React from 'react';
import QrCode from '../QrCode';

const TopActions = ({isLight, black, main, category}) => {
    let categoryClass;
    if(category){
         categoryClass = category.name.split(' ')[0].toLowerCase();

    }

    return (
        <>
        <div className={`top__actions--container ${main}`}>
        <div className="top__actions">
            {/* <div className={isLight ? styles.light : null}>WeChat: Sales</div> */}
                <a href="https://nanoporetech.com/getting-started-with-minion" className="button button--secondary">Get started</a>
                <a href="#" className="button button--primary">Talk to us</a>
                <a href="#" className={`button button--light button--keyline ${black} ${categoryClass} `}>Subscribe</a>
                <QrCode isLight={isLight} />
            </div>

        </div>

            <style jsx global>{`
            .top__actions {
                position: absolute;
                z-index: 1;
                top: 1rem;
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                align-items: flex-start;
                padding-left: 1rem;
                justify-content: flex-end;
                right: 0;
                left: 0;
            }
            .top__actions--container{
                position: relative;
                width: 100%;
                height: auto;
                margin-inline: -1vw 0px;
            }



            .top__actions .button:last-child{
                padding: .125rem .5rem;
            }

            .top__actions .button {
                margin-right: .25rem;
                padding: .25rem .5rem;
                font-size: .875rem;
            }


            @media only screen and (max-width: 769px) {
                .top__actions .button {
                clip: rect(0 0 0 0);
                position: absolute;
                }
                .button--light.button--keyline{
                    clip: rect(0 0 0 0);
                    position: absolute;
                }
            }

         `}</style>
        </>
    );
}

export default TopActions;
