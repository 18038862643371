import { faArrowAltLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export default function Landing({categories}) {
    return (
        <div>
            <h1>Welcome to the Oxford Nanopore Store</h1>
            <p>
                <FontAwesomeIcon icon={faArrowAltLeft} />&nbsp;&nbsp;
                <span>Your buying journey starts here</span>
            </p>
        </div>
    );
}
